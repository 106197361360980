@import "~bootstrap/scss/functions";

//@import "variables";

@import "~@solidwhite/vis-base/lib/sass/index.css";


/* Company colors */

$vp-blue:		#009de0 !important;
$vp-red:		#ff0956 !important;
$vp-darkred:	#ca1750 !important;
$vp-turq:		#71cac4 !important;
$vp-darkturq:	#30968f !important;
$vp-yellow:		#e2e647 !important;
$vp-darkyellow:	#d6db41 !important;

/* Page */

body {
	background-color: white;
}

.page-title {
	color: #009DE0;
}

a {
  	color: #009DE0;
}

.a:hover {
	color: #008BC7 !important;
	text-decoration: none;
}

.white-text {
	color: white !important;
}

.white-text p {
	color: white !important;
}

/*******
* Logo *
********/

.menu-logo-wrapper {
	height: 80px;
	margin: 20px;
	padding: 0;
	cursor: pointer;
}

.menu-logo-big {
	width: 260px;
	margin: 0;
}

.menu-logo-small {
	width: 40px;
	margin-bottom: 20px;
}

/* Buttons */


.btn-primary {
	color: white;
	background-color: #009DE0;
	border-color: #009DE0;
	border-radius: 0;
	outline: none;
}

	.btn-primary:hover {
		color: white !important;
		background-color: #008BC7;
		border-color: #008BC7;
	}

	.btn-primary:focus {
		outline: 2px solid #008BC7 !important;
		background-color: #008BC7 !important;
		border-color: #008BC7 !important;
	}

	.btn-primary:active {
		outline: 2px solid #008BC7 !important;
		background-color: #008BC7 !important;
		border-color: #008BC7 !important;
	}

	.btn-primary:disabled {
		color: #E6E6E6;
		background-color: #B2B3B5;
		border-color: #B2B3B5;
	}

.btn-secondary {
	color: #3F5761;
	background-color: white;
	border-color: #3F5761;
	border-radius: 0;
	outline: none;
}

	.btn-secondary:hover {
		color: #768C97 !important;
		background-color: white;
		border-color: #768C97;
	}

	.btn-secondary:focus {
		color: #768C97 !important;
		background-color: white;
		outline: 2px solid #768C97 !important;
		border-color: #768C97 !important;
	}

	.btn-secondary:active {
		outline: 2px solid #768C97 !important;
		border-color: #768C97 !important;
	}

.btn-transparent {
	color: $vp-blue;
	background-color: transparent;
	outline: none;
}

	.btn-transparent:hover {
		color: $vp-red;
	}

.download-btn-primary {
	color: white;
	background-color: #009DE0;
	border-color: #009DE0;
	border-radius: 0;
	outline: none;
}

	.download-btn-primary:hover {
		color: white !important;
		background-color: #008BC7;
		border-color: #008BC7;
	}

	.download-btn-primary:disabled {
		color: #E6E6E6;
		background-color: #B2B3B5;
		border-color: #B2B3B5;
	}

.download-btn-secondary {
	color: black;
	background-color: $vp-yellow;
	border-color: $vp-yellow;
	border-radius: 0;
	outline: none;
}

	.download-btn-secondary:hover {
		color: black;
		background-color: $vp-darkyellow;
		border-color: $vp-darkyellow;
	}


/* Badges */

.badge-primary {
	background-color: #009DE0;
	color: white;
}

/* Others */

.link-text-secondary {
	color: #bbb !important;
}

.link-text-secondary:hover {
	color: #ddd !important;
	text-decoration: none;
}

.btn-link {
	color: #074A8A;
}

.btn-link:hover {
	color: #0F64B5 !important;
	text-decoration: none;
}

.spinner-border {
  	color: #008BC7;
}

.imprint-link {
	color: #074A8A !important;
	text-decoration: none !important;
}

.widgetLink {
	color: $vp-blue;
}

	.widgetLink:hover {
		color: #0F64B5;
	}

.analytics-muted {
	color: #bbb;
}


/* Calendar */

.rdrSelected {
	color: #193461 !important;
}

.rdrDayPassive span {
	color: #ccc !important;
}

.rdrDayDisabled .rdrDayNumber span {
	color: #ccc;
}


/* Menu first */

.menu-first {
	background: #fff;
	color: #333;
}

	.menu-first .menu-link-wrapper {
		color: #333 !important;
	}

	.menu-first .menu-link-wrapper:hover {
		color: #009DE0 !important;
	}

	.menu-first .menu-link-wrapper.active {
		color: #009DE0 !important;
		border-right-color: #009DE0 !important;
	}

	.menu-first .menu-link-icon {
		color: #333 !important;
	}

	.menu-first .menu-link-text {
		color: #333 !important;
	}

.base-version p {
	color: rgba(118, 140, 151, 0.5);
}

/* Menu second */

.menu-second {
	background: #F8F8F8;
	color: #333 !important;
}

	.menu-second .menu-link-wrapper {
		color: #333 !important;
	}

	.menu-second .menu-link-wrapper:hover {
		color: #009DE0 !important;
	}

	.menu-second .menu-link-wrapper.active {
		color: #009DE0 !important;
		border-right-color: #009DE0 !important;
	}

	.menu-second .menu-link-icon {
		color: #333;
	}

	.menu-second .menu-link-text {
		color: #333;
	}

/* Form controls */

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #009DE0 !important;
}


// Outdated browser page:

#outdated {
  position: fixed;
  pointer-events:none;
  background-color: transparent !important;
  width:100%;
  height:100vh;
  z-index: 1500;
}

#outdated .vertical-center {
  pointer-events: all;
  padding-top: 4rem;
  width:100%;
  height:100vh;
  background-color:white !important;
  color: black !important;
  text-align: center;
}

#outdated h6 {
  color:black !important;
  font-size: 2rem;
}

#outdated p {
  display: none !important;
}

#buttonUpdateBrowser {
  background-color:white !important; 
  display: none !important;
}